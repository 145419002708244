//import $ from 'jquery'
//import jquery from "jquery"
//window.jQuery = jquery
//window.$ = jquery

console.log($); // ok

import DataTable from 'datatables.net-bs5'

console.log(DataTable); // ok

// Listen for DataTables initialisations
$(document).on( 'init.dt.dth', function (e, settings, json) {
  if ( e.namespace !== 'dt' ) {
    return;
  }

  var table = new $.fn.dataTable.Api( settings );
  var body = $( table.table().body() );

  if (
      $( table.table().node() ).hasClass( 'searchHighlight' ) || // table has class
      settings.oInit.searchHighlight                          || // option specified
      $.fn.dataTable.defaults.searchHighlight                    // default set
  ) {
    table
        .on( 'draw.dt.dth column-visibility.dt.dth column-reorder.dt.dth', function () {
          highlight( body, table );
        } )
        .on( 'destroy', function () {
          // Remove event handler
          table.off( 'draw.dt.dth column-visibility.dt.dth column-reorder.dt.dth' );
        } );

    // initial highlight for state saved conditions and initial states
    if ( table.search() ) {
      highlight( body, table );
    }
  }
} );




// Before caching the page, clear the search input field
$(document).on('turbo:before-cache', function() {
  if ( $('table#sensor_networks').length > 0 ) {
    $.fn.dataTable.tables( { api: true } ).search("").draw();
    $.fn.dataTable.tables( { api: true } ).destroy();
  }
});


