// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import './add_jquery'
import './init_datatables'

import * as tabler from "./tabler"
import * as jsVectorMap from "jsvectormap"
import * as world from "jsvectormap/dist/maps/world"
import * as bootstrap from "bootstrap"


